@import "../../../styles/globals";

.add-project .secondary-navigation {
//  padding: 1rem;
}

.item.active {
  border-left-color: #3F8CFF;
  color: #3F8CFF;
}
.text-red{
  color: $alert-danger-font-color-2;
}
.dropdown.show{
  display: block;
}
.dropdown.hide{
  display: none;
}
.dropdown{
  max-height: 130px;
  width: 110px;
  position: absolute;
  right: 20px;
  margin-left: -90px;
  top: 175px;
  background: #FFFFFF;
  box-shadow: 5px 5px 14px 5px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 20px;
  z-index: 5000;
}
a.disabled .title{
  color: $gray-shade-2;
}

.edit-project .btn {
  padding:  0 1rem;
}

.select-client{

  select {
    width: 12em;
    margin: 0;
    margin-left: -12.75em;
  }
}
.form-input.error{
  border: 1px solid $error-color;
}