@import "globals";

// locations icon
.location-icon {
  &.small {
    width: 45px;
    height: 45px;
  }
  &.medium {
    width: 60px;
    height: 60px;
  }
}

.location-image {
  &.small {
    width: 45px;
    height: 45px;
    border-radius: 13px;
    overflow: hidden;
    border: 1px solid $primary-border-color;
  }
  &.medium {
    width: 60px;
    height: 60px;
    border-radius: 13px;
    overflow: hidden;
    border: 1px solid $primary-border-color;
  }

  img {
    width: 100%;
  }
}
p.font-weight-bold {
  font-weight: bold;
}
@media only screen and (max-width: 480px){

  .Toastify__toast-container {
     width: unset;
     padding: 4px;
    right: 1em;
     left: unset;
     margin: 0;
    button{
      width: unset;
    }
  }
}