@import "../../styles/globals";

.ReactModal {
  &__Overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    z-index: 100000;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    background-color: rgba(0,0,0,0.6) !important;

    &--after-open {
      opacity:1
    }
    &--before-close {
      opacity: 0;
    }
  }

  &__Content {
    margin: auto;
    inset: 0 !important;
    position: absolute;
    border: 1px solid $gray-color-1;
    background: $primary-white;
    overflow: auto;
    border-radius: 10px;
    outline: none;
    padding: 20px;

    .content {
      height: -webkit-fill-available;
      max-height: 380px;
      overflow-y: scroll;
      overflow-x: hidden;
      -ms-overflow-style: none;  /* IE and Edge */
    }
    .content::-webkit-scrollbar {
      display: none;
    }

    &.language {
      overflow: unset;
      display: flex;
      flex-direction: column;

      width: 80%;
      height: 330px;
    }

    &.subscription-limit {
      max-width: 600px;
      height: 400px;
      display: flex;
      flex-direction: column;

      .image {
        max-width: 180px;
        margin: 1.5rem auto 2rem auto;
      }
    }
    &.sign-contract.add-prod {
   //   height: 80vh!important;
      max-width: 80vw;

      .row {
        height: fit-content;
        .card{
          height: fit-content;
          form{
            height: fit-content;
            .content {
              height: fit-content;
              max-height: calc(100% - 50px);
            }
          }
        }
      }
    }
    &.sign-contract {
      max-width: 600px;
      height: fit-content;
      display: flex;
      flex-direction: column;

      .image {
        max-width: 180px;
        margin: 1.5rem auto 2rem auto;
      }
    }

    .header {
      padding: 1rem 0;
      border-bottom: 1px solid #E6E8F0;
      font-weight: 700;
      font-size: 1rem;
      margin-bottom: 16px;
    }

    .head {
      display: flex;
      flex-direction: row;

      .title {
        flex:1;
      }

      .close {
        cursor: pointer;

        span {
          padding: 4px;
          text-align: center;
        }
      }
    }
  }

}

@media screen and (min-width: 768px) {
  .ReactModal__Content {
    &.language {
      max-height: 400px;
      max-width: 500px;
    }
  }
}
