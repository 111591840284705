@import "../../../styles/globals";

.secondary-navigation {
  display: flex;
  flex:1;
  flex-direction: column;
  background: $primary-white;
  box-shadow: 5px 5px 14px 5px rgba(0,0,0,0.08);
  border-radius: 10px;
  padding: 1rem 0;

  .item {
    display: flex;
    flex-direction: row;
    padding: 0.5rem 1rem;
    font-size: 0.95rem;
    color: $dark-color;
    border-left: 4px solid transparent;

    .icon {
      margin-right: 0.8rem;
    }
  }

  a.active .item {
    border-left-color: $link-active-color;
    color: $link-active-color;
  }

}