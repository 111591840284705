@import "../../styles/globals";

.layout {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: calc(100vh - 50px);
  max-height: calc(100vh - 50px);
  overflow: hidden;


  .container {
    display: flex;
    flex-direction: column;
    flex: 1;
    background: $primary-background-color;
    padding: 2rem 2rem;
    overflow-y: auto;

    .container-title {
      margin: 0 0 1.5rem 0;
    }

    .card {
      display: flex;
      flex:1;
      flex-direction: column;
      background: $primary-white;
      box-shadow: 5px 5px 14px 5px rgba(0,0,0,0.08);
      border-radius: 10px;

      .header {
        padding: 0 1rem 1rem 1rem;
        border-bottom: 1px solid $primary-border-color;
        font-weight: 700;
        font-size: 1rem;
        .search-input-container,.form-control, button,p{
          margin-top: 1rem;
          margin-bottom: 0;
        }

      }

      .content {
        padding: 1rem 1rem;
      }

      .footer {
        padding: 1rem 1rem;
      }
    }
  }
}
.price-input-container{
  position: relative;
  p{
    position: absolute;
    top: -6px;
    right: 11px;
    color: $gray-color-1;
  }
  input{
    padding-right: 46px;
    text-align: right;
  }
}
.icon-sidebar {
  border-bottom: 1px solid $primary-border-color;
  font-size: 1rem;
  padding: 0.8rem 1rem;
  color: $gray-color-1;
  position: relative;
  z-index: 1000;

  background: $primary-white;
  div:first-of-type {
    display: inline-block;
    cursor: pointer;
  }
}
.notifications-pop-up{
  position: absolute;
  top: 49px;
  right: 0;
  max-width: 300px;
  width: 300px;
  max-height: 90vh;
  background: $primary-white;
  box-shadow: 5px 5px 14px 5px rgba(0,0,0,0.08);
  border-radius: 10px;
  z-index: 5000;
  overflow-y: scroll;
}


@media (max-width: 576px) {

  .notifications-pop-up{
    max-width: 100vw;
    max-height: calc(100vh - 49px);
  }
}

.react-datepicker button.react-datepicker__navigation {
  width: 32px;
}