@import "../../../styles/globals";
.sigCanvas{
  width: -webkit-fill-available;
}
.signature-container{
  position: relative;

  .icon {
    font-size: 4rem;
    color: $primary-border-color;
  }

  .title {
    margin-top: 1rem;
    margin-bottom: 0.3rem;
    color: $primary-color;
  }

  .signature-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(255,255,255,0.6);
    display: flex;
  }

  &:hover {
    .signature-overlay {
      display: none;
    }
  }
}

.change-currency{

  .content{
    height: -webkit-fill-available;
  }
}