@import "styles/globals.scss";
@import "styles/grid.scss";
@import "styles/forms.scss";
@import "styles/tables.scss";
@import "styles/alerts.scss";
@import "styles/generals.scss";

body {
  position: relative;
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $dark-color;
  font-size: 0.95rem;
  input {
    font-family: 'Nunito Sans', sans-serif;
  }
  ::-webkit-scrollbar {
    height: 6px;
    width: 3px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $primary-background-color;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $gray-color-2;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $secondary-background-color;
  }
}

a {
  text-decoration: none;
}