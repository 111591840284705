@import "./globals";

.alert {
  background: $alert-warning-background-color;
  padding: 0.7rem 1rem;
  border-radius: 8px;
  color: $alert-warning-font-color;
  &.alert-warning{
    background: $alert-warning-background-color;
    color: $alert-warning-font-color;
    border: 1px solid $alert-warning-border-color;
  }
  &.alert-success{
    background: $alert-success-background-color;
    color: $alert-success-font-color;
  }
  &.alert-danger{
    background: $alert-danger-background-color;
    color: $alert-danger-font-color;
  }
  &.alert-info{
    background: $secondary-background-color;
    color: $primary-shade-1;
  }
  &.alert-sm{
    border-radius: 16px;
    padding: 0.5rem 0.7rem;
    border: none;
  }
}