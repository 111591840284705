@import "globals";

.table {
  display: flex;
  flex-direction: column;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-top: calc(1 * var(--bs-gutter-y));

  .table-head {
    width: 100%;

    .table-td {
      text-align: left;
      padding: 1rem 1rem;
      color: $gray-color-1;
      font-size: 0.9rem;
      font-weight: bold;
      text-overflow: ellipsis;
    }

    .row {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .table-body {
    width: 100%;
    border-bottom: 1px solid $primary-border-color;

    .table-row {
      border-top: 1px solid $primary-border-color;

      &:hover {
        background: $gray-shade-1;
      }

      .row {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }
    }

    .table-td {
      overflow: hidden;
      display: flex;
      align-items: center;
      padding: 0.5rem 1rem;
    }
  }

}

@media screen and (max-width: 1600px) {
  .table {
    overflow-x: auto;

    .table-head, .table-body {
      min-width: 400px;
    }
  }
  .table.events {
    min-width: 1160px;
  }
}

.table.contracts-client {
  .table-head, .table-body {
    min-width: 660px;
  }
}
.table.events-client {
  .table-head, .table-body {
    min-width: 680px;
  }
}
.table.contracts {
  .table-head, .table-body {
    min-width: 800px;
  }
}