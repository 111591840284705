@import "../../../styles/globals";
.clients-list{

  .table-body,.table-head{
    min-width: 910px;
  }
}

.search-input-container{
  position: relative;
  min-width: 220px;

  i{
    position: absolute;
    top: 11px;
    left: 11px;
    color: $gray-color-1;
  }
  input{
    padding-left: 34px;
  }
}